import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Box,
    Chip,
    Stack,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAgencyFilterOptions,
    getClientFilterOptions,
    getDeviceFilterOptions,
    selectAgencyFilterOptions,
    selectAgencyFilterOptionsStatus,
    selectClientFilterOptions,
    selectClientFilterOptionsStatus,
    selectDeviceFilterOptions,
    selectDeviceFilterOptionsStatus,
    clearClientFilterOptions,
    clearDeviceFilterOptions,
    selectDeviceFilterOptionsById,
    getDeviceFilterOptionsById,
    clearDeviceFilterOptionsById,
}
    from 'src/state/captures/capturesSlice';
import {
    FilterOption,
} from 'src/models/common/filterOptionData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import SearchIcon from '@mui/icons-material/Search';
import { ContentModal } from 'src/components/content-modal/ContentModal';

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export interface NotificationDevicesSelectorProps {
    // eslint-disable-next-line
    deviceAlias?: any;
    error?: boolean,
    helperText?: React.ReactNode,
    values?: string[],
    disabled?: boolean,
    onChange: (values: string[]) => void,
}

export function NotificationDevicesSelector(
    {
        deviceAlias,
        values,
        onChange,
        error,
        helperText,
        disabled,
    }: Readonly<NotificationDevicesSelectorProps>
): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openSelectDevices, setOpenSelectDevices] = useState<boolean>(false);
    const [agency, setAgency] = useState<FilterOption | null>(null);
    const [client, setClient] = useState<FilterOption | null>(null);
    const [devices, setDevices] = useState<FilterOption[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<FilterOption[]>([]);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const clientFilters = useSelector(selectClientFilterOptions);
    const deviceFilters = useSelector(selectDeviceFilterOptions);
    const agencyFiltersStatus = useSelector(selectAgencyFilterOptionsStatus);
    const clientFiltersStatus = useSelector(selectClientFilterOptionsStatus);
    const deviceFiltersStatus = useSelector(selectDeviceFilterOptionsStatus);
    const initialDevices = useSelector(selectDeviceFilterOptionsById);

    useEffect(() =>
        {
            if(initialDevices)
            {
                setDevices(initialDevices);
                dispatch(clearDeviceFilterOptionsById());
            }
        }, [dispatch, initialDevices]);
    
    useEffect(() =>
    {
        if(values)
        {
            dispatch(getDeviceFilterOptionsById(values));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() =>
    {
        setSelectedDevices(devices);
    }, [devices, dispatch]);

    function onChangeClientFilter(
        data: FilterOption | null
    ): void
    {
        if (data)
        {
            setClient(data);
            dispatch(getDeviceFilterOptions({ clientId: data?.value }));
        }
        else
        {
            setClient(null);
            dispatch(getDeviceFilterOptions({ agencyId: agency?.value }));
        }
    }

    function onChangeAgencyFilter(
        data: FilterOption | null
    ): void
    {
        if (data)
        {
            setAgency(data);
            setClient(null);
            dispatch(getClientFilterOptions({ agencyId: data?.value }));
            dispatch(getDeviceFilterOptions({ agencyId: data?.value }));
        }
        else
        {
            dispatch(getDeviceFilterOptions({}));
            clearFilters();
        }
    }
    
    function isChecked(device: string): boolean
    {
        const comparison = selectedDevices?.find(selected => selected.value === device)?.value;
        return comparison === device;
    }
    function clearFilters(): void
    {
        setAgency(null);
        setClient(null);
    }

    function handleChanges(deviceFilter: FilterOption, checked: boolean): void
    {
        if(checked)
        {
            setSelectedDevices([...selectedDevices, deviceFilter]);
        }
        else
        {
            setSelectedDevices(selectedDevices.filter(item => item.value !== deviceFilter.value));
        }
    }

    function handleOpen(): void
    {
        setOpenSelectDevices(true);
        dispatch(getAgencyFilterOptions());
    }
    function handleSaveSelected():void
    {
        setDevices(selectedDevices);
        setOpenSelectDevices(false);
        onChange(selectedDevices.map(item => item.value));
        clearFilters();
    }
    function handleCancel():void
    {
        setSelectedDevices(devices);
        setOpenSelectDevices(false);
        clearFilters();
        dispatch(getDeviceFilterOptions({}));
        
    }
    function removeItem(deviceFilter: FilterOption): void
    {
        const updatedDevices = devices.filter(item => item.value !== deviceFilter.value);
        setDevices(updatedDevices);
        const updatedValues = updatedDevices.map(item => item.value);
        onChange(updatedValues);
    }

    return (
        <>
            <ContentModal
                open={openSelectDevices}
                title={t('form.add-devices')}
                onClose={handleCancel}
                buttons={[
                    {
                        text: t('form.cancel'),
                        onClick: handleCancel,
                    },
                    {
                        text: t('form.add-devices-to-list'),
                        onClick: handleSaveSelected,
                        contained: true,
                    },
                ]}
            >

                <Grid container spacing={3} sx={{ mt: 1 }}>
                    <Grid item sm={6}>
                        <Stack
                            direction="column"
                            alignItems={'stretch'}
                            justifyContent={'flex-start'}
                            spacing={3}
                        >
                            <Autocomplete
                                id="agencies"
                                value={agency}
                                filterSelectedOptions
                                onChange={(_, value) =>
                                    onChangeAgencyFilter(value)}
                                options={agencyFilters ?? []}
                                loading={agencyFiltersStatus === RequestStatus.InProgress}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('form.agencies')}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="clients"
                                value={client}
                                filterSelectedOptions
                                disabled={!agency}
                                loading={clientFiltersStatus === RequestStatus.InProgress}
                                onChange={(_, value) =>
                                    onChangeClientFilter(value)}
                                options={clientFilters ?? []}
                                getOptionLabel={(option) => option.name}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('form.clients')}
                                    />
                                )}
                            />
                        </Stack>
                    </Grid>
                    <Grid item sm={6} sx={{ height: '100%' }}>
                        <Box
                            sx={{
                                border: '1px solid #BBBBBB',
                                width: '100%',
                                height: '100%',
                                borderRadius: 1,
                                overflow: 'auto',
                                padding: 3,
                            }}
                            textAlign={'center'}
                        >
                            {
                                deviceFiltersStatus === RequestStatus.InProgress

                                    ? <CircularProgress size="4rem" />
                                    : (
                                        <Grid container spacing={2} textAlign={'justify'}>
                                            {
                                                deviceFilters?.map(deviceFilter => (
                                                    <Grid
                                                        item
                                                        sm={6}
                                                        md={6}
                                                        lg={4}
                                                        key={deviceFilter.value}
                                                    >
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    checked={
                                                                        isChecked(
                                                                            deviceFilter.value
                                                                        )
                                                                    }
                                                                    onChange={
                                                                        (_, checked) =>
                                                                            handleChanges(
                                                                                deviceFilter,
                                                                                checked
                                                                            )
                                                                    }
                                                                />
                                                            )}
                                                            label={deviceFilter.name}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    )}
                        </Box>
                    </Grid>
                </Grid>
            </ContentModal>
            <div style={{ position: 'relative' }}>
                { !disabled ? (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{
                            position: 'absolute',
                            top: -40,
                            right: 0,
                        }}
                    >
                        <Button
                            disabled={disabled}
                            startIcon={<SearchIcon />}
                            onClick={handleOpen}
                        >
                            {t('form.add-devices')}
                        </Button>

                    </Box>
                ) : ''}

                <div
                    style={{
                        position: 'absolute',
                        overflowY: 'auto',
                        height: '100%',
                        width: '100%',
                        padding: 10,
                        zIndex: 2,
                        borderRadius: 4,
                    }}
                >
                    {devices.map(
                        device => (
                            <Chip
                                disabled={disabled}
                                key={device.value}
                                label={device.name}
                                sx={chipStyle}
                                size="small"
                                onDelete={() => removeItem(device)}
                            />
                        )
                    )}

                </div>
                <Autocomplete
                    multiple
                    id="users"
                    options={[]}
                    size="small"
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            id="devices"
                            label={t('form.devices')+ ' *'}
                            name="devices"
                            error={error}
                            helperText={helperText}
                            multiline
                            rows={5}
                        />
                    )}
                    ChipProps={{ sx: chipStyle }}
                />
            </div>
        </>
    );
}
