import {
    createAsyncThunk,
    createSlice,
    createSelector,
} from '@reduxjs/toolkit';

import { login as loginRequest } from 'src/services/authorization';
import { LoginRequest, LoginResponse } from 'src/models/authorizationModels';
import { RequestStatus } from 'src/models/requestStatus.enum';
import * as storage from 'src/utils/storage';

import { RootState } from '../store';

export interface AuthorizationState extends Partial<LoginResponse> {
    loginStatus: RequestStatus;
}

const initialState: AuthorizationState = {
    ...storage.read(storage.Keys.Token),
    ...storage.read(storage.Keys.User),
    loginStatus: storage.read(storage.Keys.Token)
        ? RequestStatus.Success
        : RequestStatus.NotStarted,
};

export const login = createAsyncThunk(
    'authorization/login',
    async ({ email, password }: LoginRequest) =>
        loginRequest(email, password)
);

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {},
    extraReducers: (builder) =>
    {
        builder
            .addCase(login.pending, (state) =>
            {
                state.loginStatus = RequestStatus.InProgress;
            })
            .addCase(login.fulfilled, (state, action) =>
            {
                const { username, role, assignations,userId } = action.payload;
                storage.write(storage.Keys.User, { username, role, assignations, userId });
                state.username = username;
                state.role = role;
                state.loginStatus = RequestStatus.Success;
            })
            .addCase(login.rejected, (state) =>
            {
                state.loginStatus = RequestStatus.Failure;
            });
    },
});

function selectState(state: RootState): AuthorizationState
{
    return state.authorization;
}

export const selectLoginStatus = createSelector(
    selectState,
    state => state.loginStatus
);


export const selectRole = createSelector(
    selectState,
    state => state.role
);

export const selectIsLoggedIn = createSelector(
    selectState,
    state => state.loginStatus === RequestStatus.Success
);

export default authorizationSlice.reducer;
