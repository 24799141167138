import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';

import {
    TableExportFormat,
    usePDFExport,
    useTableExporter,
} from '../table-exporter/TableExporterProvider';
import TableExporter from '../table-exporter/TableExporter';
import { CircularProgress } from '@mui/material';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllToExportDatas,
    selectAlertsFilter,
    selectFilters,
    selectTableDatatype,
    selectscreenshotGridView,
} from 'src/state/captures/capturesSlice';
import { useTranslation } from 'react-i18next';
import { ALLtoExport } from 'src/templates/common-export-form/ALLtoExport';
import { DataType } from 'src/models/dataType.enum';

const containerStyle = {
    textAlign: 'end',
};

export default function TableDownloadBar(): React.ReactElement
{
    const { t } = useTranslation();
    const { setExportFormat } = useTableExporter();
    const pdfExporter = usePDFExport();
    const dispatch = useDispatch();
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const alertsfilter = useSelector(selectAlertsFilter);
    const screenshotGridView = useSelector(selectscreenshotGridView);
    const filters = useSelector(selectFilters);
    const dataType = useSelector(selectTableDatatype);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormat(undefined);
    }

    const getStartIcon = (currentFormat: TableExportFormat) : React.ReactElement =>
        TableExporter.loading && format === currentFormat ?
            <CircularProgress size="1rem" /> : <DownloadIcon />;
    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');

    }
    async function handlerAllXLSXExport(): Promise<void>
    {
        if(isAlertsPage){
            dispatch(getAllToExportDatas({
                ...alertsfilter,
                pageNumber: 1,
                entriesPerPage: 10,
                exportSkipId: 0,
                exportlimit: 100,
                pdfExport: true,
                dataType:DataType.ALERTS,
                alertsdatatype:alertsfilter.alertsdatatype
            })); 
        }
        else{
            dispatch(getAllToExportDatas({
                ...filters,
                pageNumber: 1,
                entriesPerPage: 10,
                exportSkipId: 0,
                exportlimit: 100,
                pdfExport: true,
                dataType: dataType,
            }));
        }
        

    }
    return (
        <Box sx={containerStyle}>
            {
                (pdfExporter && pdfExporter.recordIds.length > 0) &&
                (
                    (isAlertsPage && (alertsfilter.isGridView === false)) || (screenshotGridView === false) ||
                    (pdfExporter && pdfExporter.detailOpen === false) || (filters.isGridView === false)
                )

                    ? (
                        <>

                            {(filters.device&& !isAlertsPage) || (alertsfilter.device && isAlertsPage) ? (
                                <ALLtoExport
                                    selectedValue={handlerAllPDFExport}
                                    formatAll={formatAll}
                                    setFormatAll={setFormatAll}
                                    xlsxExportClick={handlerAllXLSXExport}
                                />
                            )
                                : ''
                            }
                            {
                                pdfExporter.pdfEnabled !== false
                                    ? (
                                        <Button
                                            variant="text"
                                            startIcon={getStartIcon(TableExportFormat.PDF)}
                                            onClick={() => handlerPDFExport(TableExportFormat.PDF)}
                                        >
                                            {t('common.button.pdf')}
                                        </Button>
                                    )
                                    : null
                            }
                            <Button
                                variant="text"
                                startIcon={getStartIcon(TableExportFormat.XLSX)}
                                onClick={() => handlerPDFExport(TableExportFormat.XLSX) }
                            >
                                {t('common.button.xlsx')}
                            </Button>
                            <Button
                                variant="text"
                                startIcon={getStartIcon(TableExportFormat.CSV)}
                                onClick={() => handlerPDFExport(TableExportFormat.CSV)}
                            >
                                {t('common.button.csv')}
                            </Button>
                        </>
                    )
                    : null
            }
        </Box>
    );
}
