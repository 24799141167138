import { Assignations } from 'src/models/assignationsModel';
import { Token, LoginResponse } from 'src/models/authorizationModels';
import { Role } from 'src/models/roles.enum';

export interface APILoginRequest
{
    userName: string;
    password: string;
}

export interface APILoginResponse
{
    assignations: Assignations;
    userName: string;
    role: Role;
    refreshToken: string;
    accessToken: string;
    expirationDate: string;
    userId?:string;
}

export type APITokenResponse =
{
    refreshToken: string;
    accessToken: string;
    expirationDate: string;
}

export function readAPILoginResponse(data: APILoginResponse): LoginResponse
{
    const { userName, role, refreshToken, accessToken, expirationDate, assignations, userId } = data;

    return { username: userName, role, refreshToken, accessToken, expirationDate, assignations, userId };
}

export function readAPIRefreshTokenResponse(data: APITokenResponse): Token
{
    return data;
}
