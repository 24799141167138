import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'src/models/userModels';
import {
    clearUser,
    getUsers,
    selectUserFilters,
    selectAdminFilters,
    selectUserUpdateStatus,
    getInstruction,
    selectInstruction,
    resetAddInstructionStatus,
    selectInstructionStatus,
} from 'src/state/administration/administrationSlice';
import { generatePath, useNavigate } from 'react-router-dom';
import { INSTRUCTION_EDIT } from 'src/utils/routes';
import { DataTable } from 'src/components/data-table/DataTable';
import { Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Role } from 'src/models/roles.enum';
import { noop } from 'src/utils/common';
import { Officer } from 'src/models/administration/officerData.model';
import { InstructionType } from 'src/models/administration/instructionData.model';
import { Modal } from 'src/components/modal/Modal';
import InstructionDelete from 'src/pages/instruction-management/InstructionDelete';
export interface UserTableProps {
    roles?: Role[]
    onEdit?: (user: User) => void
    onConfig?: (user: User) => void
    onAlert?: (user: Officer) => void
}

export function InstructionTable({
    roles,
    onEdit = noop,
    onConfig = noop,
    onAlert = noop,
}: Readonly<UserTableProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const filters = useSelector(selectUserFilters);
    const status = useSelector(selectInstructionStatus);
    const instructionList = useSelector(selectInstruction);
    const userUpdateStatus = useSelector(selectUserUpdateStatus);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const adminFilter = useSelector(selectAdminFilters);
    const [open, setOpen] = React.useState(false);
    const [id, setId] = useState<string>();
    const [type, setType] = useState<string>();
    const [typeName, setTypeName] = useState<string>('');
    const userUpdateSuccess = userUpdateStatus === RequestStatus.Success;

    useEffect(() =>
    {
        setTimeout(() => dispatch(clearUser()), 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() =>
    {
        dispatch(
            getInstruction({
                pageNumber: 1,
                entriesPerPage,
                ...adminFilter

            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,
        entriesPerPage,
        filters,
        adminFilter,
        userUpdateSuccess]);

    function onPageChange(pageNumber: number): void
    {
        dispatch(
            getUsers({
                pageNumber,
                entriesPerPage,
                ...{ ...filters, role: roles, ...adminFilter },
            })
        );
    }
    function closeModal(): void
    {
        setOpen(false);
        dispatch(getInstruction({
            pageNumber: 1,
            entriesPerPage,

        }));
    }
    const getActionColumn = (data: InstructionType): React.ReactNode => (
        <>
            <IconButton
                aria-label="edit"
                onClick={() =>
                {
                    dispatch(resetAddInstructionStatus());
                    navigate(
                        generatePath(INSTRUCTION_EDIT, {
                            id: data._id as string,
                        })
                    );
                }}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() =>
                {
                    setId(data._id);
                    setOpen(true);
                    setType('delete');
                    setTypeName(data.type);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );

    const columns = [
        {
            label: 'Platform',
            size: 2,
            value: (data: InstructionType) => data.platform,
        },
        {
            label: 'Type',
            size: 3,
            value: (data: InstructionType) => data.type,
        },
        {
            label: 'Created Date',
            size: 3,
            value: (data: InstructionType) => new Date(data.createdAt).toLocaleString(),
        },
        {
            label: 'Last Modified',
            size: 2,
            value: (data: InstructionType) => new Date(data.updatedAt).toLocaleString(),
        },

        {
            label: 'Action',
            size: 2,
            align: 'center' as const,
            format: (data: InstructionType) => getActionColumn(data),
        },

    ];
    return (
        <>
            <DataTable<InstructionType>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...instructionList}
            />
            <Modal
                title={type==='edit'? t('profiles.edit-profile'): t('profiles.delete-profile')}
                open={open}
                showCloseButton={false}
                maxWidth="md"
                fullWidth
            >
                <Box>

                    <InstructionDelete
                        DeleteModal={closeModal}
                        open={open}
                        id={id}
                        type={typeName}
                    />

                </Box>
            </Modal>
        </>
    );
}
