import {
    Alert,
    AlertProps,
    Box,
    CircularProgress,
    Snackbar,
    SnackbarProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { Client } from 'src/models/administration/clientData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    clearConfigs,
    resetItemInConfigStatus,
    createConfiguration,
    getConfiguration,
    getParentConfiguration,
    getPartialConfiguration,
    selectConfiguration,
    selectSaveConfigurationStatus,
    selectParentConfiguration,
    selectPartialConfiguration,
    updateConfiguration,
    resetSaveConfigurationStatus,
    selectItemInConfiguration,
    selectItemInConfigurationStatus,
    setItemInConfiguration,
    resetConfigurationErrorContext,
    selectConfigurationErrorContext,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';

import {
    ConfigurationOwner,
    ConfigurationParams,
    ConfigurationDetail,
    ParentConfigurationParams,
} from 'src/models/administration/configurationData.model';
import { ConfigurationPanel } from 'src/templates/configuration-panel/ConfigurationPanel';

export interface ClientConfigFormModalProps {
    client: Client
    context: ConfigurationOwner
    open?: boolean
    onClose?: () => void
    onSuccess?: () => void
    onFailure?: () => void
}

const emptyPartial: ConfigurationDetail = {
    services: [],
    blockedApps: [],
    blockedUrls: [],
    blockUninstall: false,
    blockAccessSetting: false,
    disableConfig: false,
    isLogCapture: false,
    isUninstallNotification: true,
};

export function ClientConfigFormModal({
    client,
    context,
    open = false,
    onClose = noop,
    onSuccess = noop,
    onFailure = noop,
}: Readonly<ClientConfigFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const owner = useSelector(selectItemInConfiguration);
    const ownerRetrieveStatus = useSelector(selectItemInConfigurationStatus);
    const [configurationId, setConfigurationId] = useState<string>('');
    const configuration = useSelector(selectConfiguration);
    const parentConfiguration = useSelector(selectParentConfiguration);
    const partialConfiguration = useSelector(selectPartialConfiguration);
    const isConfigurationError = useSelector(selectConfigurationErrorContext);
    const saveStatus = useSelector(selectSaveConfigurationStatus);
    const [notificationState, setNotificationState] =
        useState<SnackbarProps & AlertProps>();
    const [partialConfigurationToUse, setPartialConfigurationToUse] =
        useState<ConfigurationDetail | undefined>();
    const [fallbackConfigurationToUse, setFallbackConfigurationToUse] =
        useState<ConfigurationDetail | undefined>();

    function onSave(configurationDetail: ConfigurationDetail): void
    {
        if (configurationId)
        {
            partialConfiguration &&
                dispatch(
                    updateConfiguration({
                        ...partialConfiguration,
                        ...configurationDetail,
                    })
                );
        }
        else if (client)
        {
            dispatch(
                createConfiguration({
                    ...partialConfiguration,
                    id: configurationId,
                    ownerType: context,
                    ownerId: client.id,
                    ...configurationDetail,
                })
            );
        }
    }

    function onCancel(): void
    {
        setPartialConfigurationToUse(
            partialConfiguration
                ? { ...partialConfiguration }
                : { ...emptyPartial }
        );
        setFallbackConfigurationToUse(
            parentConfiguration ? { ...parentConfiguration } : undefined
        );
        onClose();
    }

    function onCloseNotification(): void
    {
        dispatch(resetSaveConfigurationStatus());
        dispatch(resetConfigurationErrorContext());
        onClose();
    }

    useEffect(() =>
    {
        if (
            partialConfiguration &&
            partialConfiguration.ownerId === client.id
        )
        {
            setPartialConfigurationToUse(partialConfiguration);
            setConfigurationId(partialConfiguration.ownerId);
        }
        else
        {
            setPartialConfigurationToUse(undefined);
        }
    }, [client, partialConfiguration]);

    useEffect(() =>
    {
        if (configuration)
        {
            if (configuration.ownerId === client.id && configuration.id)
            {
                dispatch(getPartialConfiguration(configuration.id));
            }
            else
            {
                setPartialConfigurationToUse(emptyPartial);
            }
        }
    }, [client, configuration, dispatch]);

    useEffect(() =>
    {
        if (parentConfiguration)
            setFallbackConfigurationToUse(parentConfiguration);
    }, [parentConfiguration]);

    useEffect(() =>
    {
        if (owner && client)
        {
            const configurationParams: ConfigurationParams = {
                ownerId: client.id,
                ownerType: context,
            };

            const parentConfigurationParams: ParentConfigurationParams = {
                child: owner,
                ownerType: context,
            };

            dispatch(getConfiguration(configurationParams));
            dispatch(getParentConfiguration(parentConfigurationParams));
        }
    }, [client, owner, dispatch, context]);

    useEffect(() =>
    {
        if (client)
        {
            const configurationParams: ConfigurationParams = {
                ownerId: client.id,
                ownerType: context,
            };

            dispatch(setItemInConfiguration(configurationParams));
        }
    }, [client, context, dispatch]);

    function handleSaveStatus(): void
    {
        let message = '';
        const subject = t('common.nouns.configuration');

        if (saveStatus === RequestStatus.Success)
        {
            message = t('common.messages.saved-successfully', { subject });
            onSuccess();
            dispatch(resetItemInConfigStatus());
            dispatch(resetSaveConfigurationStatus());
        }
        else if (saveStatus === RequestStatus.Failure)
        {
            message = t('common.messages.something-wrong');
            onFailure();
            dispatch(resetSaveConfigurationStatus());
        }

        setNotificationState({
            open: !!message,
            message,
            severity:
                saveStatus === RequestStatus.Success ? 'success' : 'error',
        });
    }

    useEffect(() =>
    {
        if (isConfigurationError)
        {
            setNotificationState({
                open: true,
                message: t('common.messages.something-wrong'),
                severity: 'error',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfigurationError]);

    useEffect(() =>
    {
        handleSaveStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, onSuccess, onFailure, saveStatus, t]);

    useEffect(() =>
    {
        return () =>
        {
            dispatch(clearConfigs());
        };
    }, [dispatch]);

    if (!client)
    {
        return <CircularProgress />;
    }

    return (
        <Modal
            title={
                client.firstName +
                ' ' +
                client.lastName +
                ' ' +
                t('common.nouns.configuration')
            }
            open={open}
            showCloseButton={false}
            maxWidth="md"
            fullWidth
        >
            <Box>
                {ownerRetrieveStatus === RequestStatus.InProgress || !owner ? (
                    <CircularProgress />
                ) : (
                    <Box>
                        {' '}
                        {partialConfigurationToUse && fallbackConfigurationToUse ? (
                            <ConfigurationPanel
                                context={context}
                                ownerId={client.id}
                                configuration={partialConfigurationToUse}
                                fallbackConfiguration={fallbackConfigurationToUse}
                                onSave={onSave}
                                onCancel={onCancel}
                                isLoading={
                                    saveStatus === RequestStatus.InProgress}
                                disableConfig={false}
                                isLogCapture={false}
                                isUninstallNotification={false}
                            />
                        ) : (
                            <CircularProgress />
                        )}
                    </Box>
                )}
                <Snackbar
                    autoHideDuration={6000}
                    open={notificationState?.open}
                    onClose={onCloseNotification}
                >
                    <Alert severity={notificationState?.severity}>
                        {notificationState?.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Modal>
    );
}
