import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    DropdownOption,
    DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { Platform } from 'src/models/platforms.enum';
import { noop } from 'src/utils/common';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';

export interface DevicesFormProps
{
    id?: string;
    initialValues?: DeviceFormValues;
    edit?: boolean;
    loading?: boolean;
    loadingDelete?: boolean;
    onSubmit?: (data: DeviceFormValues) => void;
    onCancel?: () => void;
    onSendActivationCode?: () => void;
    onSendDelete?: () => void;
}

export interface DeviceFormValues
{
    alias: string;
    platform: Platform;
    agencyId?: string;
    agencyName?: string;
    enabled: boolean;
    activated?: boolean;
    clientId?: string;
    clientName?: string;
    clientPhones?: string[];
    createdBy?: string;
    createdAt?: Date;

    activatedAt?: Date;
    deviceModel?: string;
    lastConnectAt?: Date;


    localName?: string;
    ipAddress?: string;
    osVersion?: string;
    totalRAM?:number;
    availableRAM?:number;
    versionInstalled?: string;
    versionUpdateOn?: Date
    macAddress?: string;

    disableDevice: boolean;
}

export const DEFAULT_VALUES: DeviceFormValues = {
    alias: '',
    platform: '' as Platform,
    enabled: true,
    activated: true,
    disableDevice: true,
};

export function DeviceForm({
    id,
    initialValues = DEFAULT_VALUES,
    edit = false,
    loading = false,
    loadingDelete = false,
    onSubmit = noop,
    onCancel = noop,
    onSendActivationCode: onSendingCode = noop,
    onSendDelete = noop,
}: Readonly<DevicesFormProps>): React.ReactElement
{
    const { t } = useTranslation();

    const [values, setValues] = useState<DeviceFormValues>(DEFAULT_VALUES);
    const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});

    function onAliasChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        setValues((current) => ({
            ...current,
            alias: event.target.value,
        }));
    }

    function onPlatformSelect(value: unknown): void
    {
        setValues((current) => ({
            ...current,
            platform: value as Platform,
        }));
    }

    function onEnabledStatusChange(_event: unknown, value: boolean): void
    {
        setValues((current) => ({
            ...current,
            enabled: value,
        }));
    }

    function onDisableOfficerChange(_event: unknown, value: boolean): void
    {
        setValues((current) => ({
            ...current,
            disableDevice: value,
        }));
    }

    function onBlur(field: string):
        (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    {
        return (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
        {
            const { value } = event.target;

            setFormErrors((current) => ({
                ...current,
                [field]: !value,
            }));
        };
    }

    function getPlatformOptions(): DropdownOption[]
    {
        return Object.entries(Platform).map(([name, value]) => ({
            name,
            value,
        }));
    }

    function onSubmitHandler(event: React.FormEvent): void
    {
        event.preventDefault();

        onSubmit(values);
    }

    function updatedDate(
        version: string | undefined,
        date: string | number | Date | undefined
    ): string
    {
        const currentDate = date ? new Date(date) : new Date();
        const formattedDate = `${(currentDate.getMonth() + 1)
            .toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')
        }-${currentDate.getFullYear()}`;

        const versionString = version ? version + ' Updated On ' +
            formattedDate + ' ' + currentDate.toLocaleTimeString('en-GB') : '';

        return versionString;
    }

    useEffect(() =>
    {
        setValues(initialValues);
    }, [initialValues]);

    const renderEditFields = (): React.ReactNode => (
        <>
            <Grid item xs={12}>
                <TextField
                    label={t('common.properties.created-by')}
                    value={
                        (values.createdBy !== 'undefined undefined')
                            ? values.createdBy : 'User Deleted'}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('common.properties.created-at')}
                    value={values.createdAt ?? ''}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('common.nouns.agency')}
                    value={initialValues.agencyName}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={t('common.nouns.client')}
                    value={initialValues.clientName}
                    fullWidth
                    disabled
                />
            </Grid>


            <Grid item xs={12}>
                <TextField
                    label={t('Device Model')}
                    value={initialValues.deviceModel}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={t('Local Name')}
                    value={initialValues.localName}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={t('Mac Address')}
                    value={initialValues.macAddress}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={t('i.p. address')}
                    value={initialValues.ipAddress}
                    fullWidth
                    disabled
                />
            </Grid>

            { initialValues?.osVersion && (
                <Grid item xs={12}>
                    <TextField
                        label={t('OS Version')}
                        value={initialValues?.osVersion}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}

            { initialValues?.totalRAM && (
                <Grid item xs={12}>
                    <TextField
                        label={t('devices.total-ram-size')}
                        value={initialValues?.totalRAM}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            { initialValues?.availableRAM && (
                <Grid item xs={12}>
                    <TextField
                        label={t('devices.available-ram-size')}
                        value={initialValues?.availableRAM}
                        fullWidth
                        disabled
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    label={t('Software Version')}
                    value={updatedDate(
                        initialValues.versionInstalled,
                        values.versionUpdateOn
                    )}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={t('Last Connection')}
                    value={initialValues.lastConnectAt}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label={t('Activated at')}
                    value={initialValues.activatedAt}
                    fullWidth
                    disabled
                />
            </Grid>

            <Stack
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-around'}
                sx={{ ml: 1, mt: 3, mb: 3 }}
            >
                <>
                    <Grid sx={{ ml: 2 }}>
                        {t('common.nouns.enabled')}
                        <ToggleSwitch
                            sx={{ ml: 1 }}
                            checked={values.enabled}
                            onChange={onEnabledStatusChange}
                            disabled={loading}
                        />
                    </Grid>

                    <Grid sx={{ ml: 12 }}>
                        {t('common.nouns.activated')}
                        <ToggleSwitch
                            sx={{ ml: 1 }}
                            checked={initialValues.activated}
                            disabled
                        />
                    </Grid>

                    {(userIsInRole([Role.Administrator,
                        Role.AgencyAdministrator,
                        Role.Employee])) ? (
                            <Grid sx={{ ml: 9 }}>
                                {t('common.actions.block-officer')}
                                <ToggleSwitch
                                    sx={{ ml: 1 }}
                                    checked={values.disableDevice}
                                    onChange={onDisableOfficerChange}
                                />
                            </Grid>
                        )
                        : null
                    }
                </>
            </Stack>
        </>
    );

    const renderButtons = (): React.ReactNode => (
        <>
            {edit
                ? (
                    <Stack direction="row" gap={1}>
                        {userIsInRole([
                            Role.Administrator,
                            Role.Employee,
                            Role.AgencyAdministrator,
                        ]) && (
                            <Button
                                type="button"
                                variant="contained"
                                disabled={loading}
                                onClick={onSendingCode}
                            >
                                {t('common.actions.send-activation-code')}
                            </Button>
                        )
                        }
                    </Stack>
                )
                : null
            }
            <Stack direction="row" gap={1}>
                <Button
                    type="button"
                    variant="outlined"
                    disabled={loading}
                    onClick={onCancel}
                >
                    {
                        edit
                            ? t('common.actions.close')
                            : t('common.actions.cancel')
                    }
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={
                        !(values.alias && values.platform) ||
                        (values.disableDevice && userIsInRole([
                            Role.Officer,
                            Role.OfficerSupervisor,
                        ]))}
                >
                    {t('common.actions.save')}
                </Button>
            </Stack>
        </>
    );

    return (
        <Box>
            <form onSubmit={onSubmitHandler}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="alias"
                            label={t('data-type.table.device-alias')}
                            value={values.alias}
                            onChange={onAliasChange}
                            onBlur={onBlur('alias')}
                            autoComplete="off"
                            disabled={loading}
                            error={formErrors['alias']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DropdownSelector
                            name="platform"
                            label={t('data-type.table.platform')}
                            options={getPlatformOptions()}
                            value={values.platform}
                            onChange={onPlatformSelect}
                            onBlur={onBlur('platform')}
                            disabled={loading || edit}
                            error={formErrors['platform']}
                            fullWidth
                        />
                    </Grid>
                    {
                        edit ? renderEditFields() : null
                    }
                </Grid>
                <Stack
                    direction="row"
                    justifyContent={edit ? 'space-between' : 'flex-end'}
                    padding="1em 0"
                    gap={1}
                >
                    {
                        loading ? <CircularProgress /> : renderButtons()
                    }
                </Stack>
                {
                    userIsInRole([Role.Administrator]) && edit && (
                        loadingDelete
                            ? <CircularProgress />
                            : (
                                <Button
                                    sx={{ marginRight: 'auto' }}
                                    type="button"
                                    variant="contained"
                                    onClick={onSendDelete}
                                >
                                    {t('common.actions.delete')}
                                </Button>
                            )
                    )
                }
            </form>
        </Box>
    );
}
