import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalDeviceData } from 'src/models/captures/externalDeviceData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getExternalDevices,
    selectFilters,
    selectExternalDevices,
    selectExternalDevicesStatus,
    getExternalDeviceDetails,
    selectExternalDeviceDetails,
    selectExternalDeviceDetailsStatus,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';

export function ExternalDeviceAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const externalDevicesStatus = useSelector(selectExternalDevicesStatus);
    const externalDevicesData = useSelector(selectExternalDevices);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const externalDeviceDetailsStatus = useSelector(
        selectExternalDeviceDetailsStatus
    );
    const externalDeviceDetailsData = useSelector(selectExternalDeviceDetails);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (externalDeviceId: ExternalDeviceData): void =>
    {
        openRecordData(externalDeviceId.id);

        dispatch(getExternalDeviceDetails(externalDeviceId.id));

        if (!externalDeviceId.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: externalDeviceId.id,
                dataType: DataType.EXTERNAL_MEDIA,
            }));
        }
        setShowModal(true);
    };
    function onPageChange(pageNumber: number): void
    {
        if (externalDevicesData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(externalDevicesData?.contents[
                    externalDevicesData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }


    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getExternalDevices({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ?
                currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: ExternalDeviceData):
    React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.EXTERNAL_MEDIA}
            onChange={() =>
            {
                onPageChange(externalDevicesData?.currentPage ?? 1);
            }}
        />
    );
    const openDetailColumn = (data: ExternalDeviceData):
    React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => handleOpen(data)}
        >
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: ExternalDeviceData) =>
                t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: ExternalDeviceData) =>
                getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: ExternalDeviceData) =>
                new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: ExternalDeviceData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: ExternalDeviceData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.device-name'),
            size: 3,
            value: (data: ExternalDeviceData) => data.deviceName,
            sortByType: 'deviceName',
        },
        {
            label: t('data-type.table.device-type'),
            size: 2,
            value: (data: ExternalDeviceData) => data.deviceClass,
            sortByType: 'deviceClass',
        },
        {
            label: t('data-type.table.activity'),
            size: 3,
            value: (data: ExternalDeviceData) => data.activity,
            sortByType: 'activity',
        },
        {
            label: t('data-type.table.file-name'),
            size: 3,
            value: (data: ExternalDeviceData) => data.fileName,
            sortByType: 'fileName',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format:
                (data: ExternalDeviceData) =>
                    openDetailColumn(data),
        },
    ];
    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: ExternalDeviceData) =>
                timeZoneFormat(
                    new Date(data?.receivedDate),
                    USER_TIME_ZONE
                ),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: ExternalDeviceData) =>
                timeZoneFormat(
                    new Date(data?.capturedDate),
                    USER_TIME_ZONE
                ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: ExternalDeviceData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ExternalDeviceData) => data.username,
        },
        {
            label: t('data-type.table.device-type'),
            text: (data: ExternalDeviceData) => data.deviceClass,
        },
        {
            label: t('data-type.table.file-name'),
            text: (data: ExternalDeviceData) => data.fileName,
        },
        {
            label: t('data-type.table.activity'),
            text: (data: ExternalDeviceData) => data.activity,
        },
        {
            label: t('data-type.table.file-size'),
            text: (data: ExternalDeviceData) => data.fileSize,
        },
    ];


    useEffect(() =>
    {
        if (externalDevicesData)
        {
            setLastPage(Math.floor(
                externalDevicesData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.EXTERNAL_MEDIA,
                recordIds: externalDevicesData.contents.map(
                    (item) => item.id
                ),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(externalDevicesData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.EXTERNAL_MEDIA)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalDevicesData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<ExternalDeviceData>
                title={t('data-type.details.external-devices-title')}
                open={showModal}
                data={externalDeviceDetailsData}
                detailItems={detailItems}
                isLoading={externalDeviceDetailsStatus
                    === RequestStatus.InProgress}
                onClose={() => setShowModal(false)}
                comments={{
                    contentId: externalDeviceDetailsData?.id,
                    type: CommentType.EXTERNAL_MEDIA,
                }}
            />

            {useDesktopScreen() ? (
                <DataTable<ExternalDeviceData>
                    isLoading={externalDevicesStatus
                        === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...externalDevicesData}
                />
            ) : (
                <DataTableMobile
                    isLoading={externalDevicesStatus
                        === RequestStatus.InProgress}
                    contents={externalDevicesData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={externalDevicesData?.numberOfEntries}
                    currentPage={externalDevicesData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
