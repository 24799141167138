import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { DataDetailComment, DataDetailCommentProps } from './DataDetailComment';
import { Box, Button, CircularProgress } from '@mui/material';
import { ContentModal, ContentModalButtonProps } from 'src/components/content-modal/ContentModal';
import { TableExportFormat } from 'src/components/table-exporter/TableExporterProvider';
import TableExporter from 'src/components/table-exporter/TableExporter';
import DownloadIcon from '@mui/icons-material/Download';
import { DataType } from 'src/models/dataType.enum';
import { useSelector } from 'react-redux';
import { selectGeolocationslistDevice } from 'src/state/captures/capturesSlice';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
export interface DetailsModalProps<DetailModalType> {
    open: boolean,
    pdfEnabled?: boolean;
    dataType?: DataType | string,
    title?: string,
    detailsTitle?: string,
    data?: DetailModalType,
    detailItems?: DetailedItem<DetailModalType>[],
    onClose?: () => void,
    children?: React.ReactElement
    isLoading?: boolean,
    buttons?: ContentModalButtonProps[],
    comments?: DataDetailCommentProps,
}

export interface DetailedItem<DetailModalType> {
    label: string;
    text?: ((data: DetailModalType) => string | number);
}

export function DataDetailsModal<DetailModalType>(
    {
        open,
        pdfEnabled,
        dataType,
        title,
        detailsTitle,
        onClose,
        detailItems,
        data,
        children,
        isLoading,
        buttons,
        comments,
    }: Readonly<DetailsModalProps<DetailModalType>>
): React.ReactElement
{
    const { t } = useTranslation();
    const geolocationData = useSelector(selectGeolocationslistDevice);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const [pdfEnable, setPdfEnable] = useState<boolean>(true);
    const [details, setDetails] = useState<boolean>(false);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    useEffect(() =>
    {
        if (pdfEnabled === false || dataType === DataType.SMS)
        {
            setPdfEnable(false);
        }
        if (children || pdfEnable)
        {
            setDetails(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfEnabled, dataType, children]);

    useEffect(()=>{

     if((geolocationData?.contents.length === 0) && (dataType === DataType.GEOLOCATION)){
        setPdfEnable(false)
     }
     else if(dataType === DataType.GEOLOCATION){
        setPdfEnable(true)
     }
    },[geolocationData])

    // eslint-disable-next-line
    function getRecordIds(comments: any): any[] {
        if (comments?.content)
        {
            return [comments.content];
        }
        else if (comments?.contentId)
        {
            return [comments.contentId];
        }
        else
        {
            return [];
        }
    }
    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        // eslint-disable-next-line
            const recordIds = getRecordIds(comments);

        if (tableExportFormat === TableExportFormat.PDF)
        {
            let exportParams;
            if (dataType === DataType.GEOLOCATION)
            {
                exportParams = {
                    type: DataType.GEOLOCATION,
                    recordIds: geolocationData?.contents.map((item) => item.id) ?? [],
                };
            }
            else if (dataType === DataType.MMS || (comments && comments.type === 'MMS'))
            {

                exportParams = {
                    type: DataType.SMS,
                    recordIds,
                };
            }
            else if (dataType === DataType.PROCESSES || (comments && comments.type === 'Processes'))
            {
                exportParams = {
                    type: DataType.PROCESSES,
                    recordIds,
                };
            }

            else
            {
                exportParams = {
                    type: dataType ?? (comments?.type ?? ''),
                    recordIds: comments?.contentId ? [comments.contentId] : [],
                };
            }

            await TableExporter.asPDF(exportParams);
        }
        setFormat(undefined);
    }
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() =>
    {
        if (open)
        {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null)
            {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <ContentModal
            {...{ open, title, isLoading, onClose, buttons }}
        >
            <Grid
                container
                height="100%"
                direction="row"
                spacing={3}
            >
                <Grid
                    item
                    xs={(comments && (isAlertsPage && dataType!==DataType.GEOLOCATION) || (comments&& !isAlertsPage)) ? 8 : 12}
                    height="100%"
                >
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        spacing={2}
                    >
                        {detailItems ? (
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    {t('data-type.details.record-detail')}
                                </Typography>
                                <Grid container style={{ gridColumnGap: '30px' }}>
                                    {detailItems?.map((detailItem, key) => (
                                        <Grid
                                            key={`row-${key + 1}`}
                                            item
                                            xs={5.5}
                                        >
                                            <label style={{ marginRight: '5px' }}>
                                                {detailItem.label}
                                                :
                                            </label>
                                            <span
                                                style={{
                                                    maxWidth: '90%',
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                {
                                                    data && detailItem.text
                                                    &&
                                                    detailItem.text(data as DetailModalType)

                                                }
                                            </span>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ) : ''}
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexFlow: 'column nowrap',
                                flex: '1 0',
                            }}
                        >
                            {details ?
                                (
                                    <div>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            gutterBottom
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.grey[500],
                                            }}
                                        >
                                            {detailsTitle ?? t('data-type.details.details')}
                                        </Typography>
                                        <Box sx={{ flex: '1 0' }}>
                                            {pdfEnable &&
                                                (
                                                    <Button
                                                        variant="text"
                                                        startIcon={
                                                            TableExporter.loading &&
                                                                format === TableExportFormat.PDF
                                                                ? <CircularProgress size="1rem" />
                                                                : <DownloadIcon />
                                                        }
                                                        onClick={() =>
                                                            handlerPDFExport(TableExportFormat.PDF)
                                                        }
                                                    >
                                                        PDF
                                                    </Button>
                                                )
                                            }
                                            {children}
                                        </Box>
                                    </div>
                                ) : ''
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {((comments && (isAlertsPage&& dataType!==DataType.GEOLOCATION)) || (comments&& !isAlertsPage)) && (
                    <Grid
                        item
                        xs={4}
                        height="100%"
                    >
                        <DataDetailComment {...comments} />
                    </Grid>
                )
                }
            </Grid>
        </ContentModal>
    );
}
