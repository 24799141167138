import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';


export interface ContentModalButtonProps {
    text: string,
    onClick?: () => void,
    contained?: boolean,
    icon?: React.ReactElement,
}
export interface ContentModalProps {
    open: boolean,
    title?: string,
    onClose?: () => void,
    children?: React.ReactElement
    isLoading?: boolean,
    buttons?: ContentModalButtonProps[],
}

export function ContentModal(
    {
        open,
        title,
        onClose,
        children,
        isLoading,
        buttons,
    }: Readonly<ContentModalProps>
): React.ReactElement
{
    return (
        <Dialog
            sx={{ zIndex: (theme) => theme.zIndex.modal }}
            maxWidth="xl"
            fullWidth={true}
            open={open}
            onClose={onClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                <Typography
                    variant="h4"
                    component="h1"
                    align="right"
                    sx={{
                        padding: '3px 40px 10px 0',
                        color: (theme) => theme.palette.grey[400],
                        borderBottom: '1px solid #E8E8E8',
                        textTransform: 'none',
                    }}
                >
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: 15,
                            right: 15,
                            color: (theme) => theme.palette.grey[400],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={
                    {
                        display: 'flex',
                        height: '100vh',
                        minWidth: '50vw',
                    }
                }
            >
                {isLoading
                    ? (
                        <CircularProgress size="4rem" />
                    )
                    :
                    children
                }
            </DialogContent>
            {(buttons && buttons?.length > 0) && (
                <DialogActions
                    sx={{
                        borderTop: '1px solid #E8E8E8',
                        margin: '16px 24px',
                    }}
                >
                    {buttons?.map((button, index) => (
                        <Button
                            key={`row-${index + 1}`}
                            variant={button.contained ? 'contained' : 'text'}
                            startIcon={button.icon}
                            onClick={button.onClick}
                            sx={{ margin: 2 }}
                        >
                            {button.text}
                        </Button>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    );
}
